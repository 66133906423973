import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Subject, map, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import {
  PaginationParams,
  setPaginationHttpParams,
  setSortingHttpParams,
  SortingParams,
} from '../core/helpers/http-filters-params';
import { OrderPriority } from '../shared/enum/order-priority';
import { ApiPlatformResponse } from '../shared/interfaces/api-platform-response';
import { Content } from '../shared/interfaces/content';
import { NumberFilter, StringFilter } from '../shared/interfaces/filters';
import { Rubric } from '../shared/interfaces/rubric';
import { Rule } from '../shared/interfaces/rule';

export interface RuleFilters {
  theme?: string | null;
  type?: string | null;
  userId?: number[] | null;
  created?: Date | null;
  saved?: Date | null;
  label?: string | null;
}

export type RuleParams = PaginationParams &
  SortingParams & {
    filters?: RuleFilters;
  };

export interface RuleArtistWithRoleFilterUpsertData {
  role_tech_label: string;
  artist_ids: number[];
}

export interface RuleCountryFilterUpsertData {
  country_code: string;
  warning_tech_labels?: string[];
  price?: NumberFilter | null;
  premium_price?: NumberFilter | null;
  retailer_price?: NumberFilter | null;
  promo_price?: NumberFilter | null;
  custom_promo_price?: NumberFilter | null;
}

export interface RuleFilterUpsertData {
  title?: StringFilter;
  keyword?: StringFilter;
  type_business_tech_labels?: string[];
  isrc?: StringFilter;
  artists_with_role?: RuleArtistWithRoleFilterUpsertData[];
  licence_tech_labels?: string[];
  theme_tech_labels?: string[];
  sale_mode_tech_labels?: string[];
  production_countries?: string[];
  countries?: RuleCountryFilterUpsertData[];
}

export interface RuleUpsertData {
  id?: number;
  label: string;
  filters: RuleFilterUpsertData;
  maxContents: number;
  orderMode: 'fixed' | 'random';
  orderPriority: OrderPriority;
  orderBy?: string;
  direction?: 'ASC' | 'DESC';
}

export interface RuleExecutedEvent {
  rule: Rule;
  rubric: Rubric;
}

@Injectable({
  providedIn: 'root',
})
export class RuleService {
  savedSubject = new Subject<Rule>();
  deletedSubject = new Subject<Rule>();
  executedSubject = new Subject<RuleExecutedEvent>();
  duplicatedSubject= new Subject<string>();

  get saved(): Observable<Rule> {
    return this.savedSubject.asObservable();
  }
  get deleted(): Observable<Rule> {
    return this.deletedSubject.asObservable();
  }
  get executed(): Observable<RuleExecutedEvent> {
    return this.executedSubject.asObservable();
  }
  get duplicated(): Observable<string> {
    return this.duplicatedSubject.asObservable();
  }
  constructor(private http: HttpClient) {}

  all(params?: RuleParams): Observable<ApiPlatformResponse<Rule[]>> {
    let httpParams = new HttpParams();

    if (params?.filters?.theme) {
      httpParams = httpParams.set('theme', params.filters.theme);
    }
    // if (params?.filters?.userId) {
    //   httpParams = httpParams.append('user_id', params.filters.userId);
    // }
    if (params?.filters?.userId && params.filters.userId.length > 0) {
      params.filters.userId.forEach((userId) => {
        httpParams = httpParams.append('id[]', userId);
      });

    }
    if (params?.filters?.created) {
      httpParams = httpParams.set(
        'create_date',
        params.filters.created.toISOString()
      );
    }
    if (params?.filters?.saved) {
      httpParams = httpParams.set(
        'update_date',
        params.filters.saved.toISOString()
      );
    }
    if (params?.filters?.label) {
      httpParams = httpParams.append('label', params.filters.label);
    }

    httpParams = setPaginationHttpParams(httpParams, params);
    httpParams = setSortingHttpParams(httpParams, params);

    return this.http.get<ApiPlatformResponse<Rule[]>>(
      `${environment.api}/rules`,
      {
        params: httpParams,
      }
    );
  }

  find(id: number): Observable<Rule> {
    return this.http.get<Rule>(`${environment.api}/rules/${id}`);
  }

  upsert(data: RuleUpsertData): Observable<Rule> {
    return this.http
      .post<Rule>(`${environment.api}/rules`, {
        rule: {
          id: data.id,
          label: data.label,
          filters: data.filters,
          max_content_number: data.maxContents,
          order_mode: data.orderMode,
          order_content: data.orderPriority,
          order_items_label: data.orderBy,
          order_items: data.direction,
        },
      })
      .pipe(
        tap((rule) => {
          this.savedSubject.next(rule);
        })
      );
  }

  delete(rule: Rule): Observable<void> {
    return this.http.delete<void>(`${environment.api}/rules/${rule.id}`).pipe(
      tap(() => {
        this.deletedSubject.next(rule);
      })
    );
  }

  preview(
    rule: Rule,
    rubric: Rubric,
    pagination?: PaginationParams
  ): Observable<ApiPlatformResponse<Content[]>> {

    let httpParams = new HttpParams();
    httpParams = httpParams.append('rubric_id', rubric.id)
    httpParams = httpParams.append('rule_id', rule.id)

    httpParams = setPaginationHttpParams(httpParams, pagination);

    return this.http.get<ApiPlatformResponse<Content[]>>(
      `${environment.api}/preview-rule`,
      {
        params: httpParams
      }
    );
  }

  execute(rule: Rule, rubric: Rubric): Observable<void> {
    return this.http
      .get<void>(`${environment.api}/execute-rule`, {
        params: {
          rubric_id: rubric.id,
          rule_id: rule.id,
        },
      })
      .pipe(
        tap(() =>
          this.executedSubject.next({
            rule,
            rubric,
          })
        )
      );
  }

  duplicate(ids: string): Observable<void> {
    return this.http
      .post<void>(`${environment.api}/duplicate-rules`,{
        rules_ids: ids
      })
      .pipe(
        tap(() =>
          this.duplicatedSubject.next(ids)
        )
      );
  }

  getRuleExecutionDate(){
    return this.http.get<any>(`${environment.api}/rundeck/get-job-forecast?job_id=6849a1a4-1f83-4422-af76-a94224d9b73a`)
    .pipe(map((res: {enabled: boolean, scheduleEnabled: boolean, futureScheduledExecutions: string[]}) => {
      return {enabled: res.enabled,
              scheduleEnabled: res.scheduleEnabled,
              futureScheduledExecutions: res.futureScheduledExecutions}
    }));
  }
}
